export function setupMobileMenu() {
    setupEvents();

    /**
     * Setup event listeners
     */
    function setupEvents() {
        const hamburger = getHamburger();

        if (hamburger) {

            hamburger.addEventListener('click', () => {
                toggleHamburger();
            })
        }
    }

    /**
     * Toggle hamburger menu navigation
     */
    function toggleHamburger() {
        const navParts = getNavigationPartsElement();
        const navBar = getNavigationBars();

        if (navParts) {

            if (navParts.style.display === 'block') {
                navParts.style.display = 'none';

                navBar.forEach((item) => {
                    item.style.position = 'sticky';
                })

            } else {
                navParts.style.display = 'block';

                navBar.forEach((item) => {
                    item.style.position = 'fixed';
                    item.style.top = '0px';
                })
            }
        }
    }

    /**
     * Get navigation part elements
     * This is basically a screen with menu for mobile view
     *
     * @return {Element|null}
     */
    function getNavigationPartsElement() {
        return document.getElementsByClassName('navigation-parts') ? document.getElementsByClassName('navigation-parts')[0] : null;
    }

    /**
     * get navigation bar elements
     * We have two types of navigation bar elements
     *
     * One is for home and the other is used on all other pages
     *
     * @return {[]}
     */
    function getNavigationBars() {
        let bar1 = document.getElementsByClassName('pimcore_area_navigation-bar') ? document.getElementsByClassName('pimcore_area_navigation-bar')[0] : null;
        let bar2 = document.getElementsByClassName('pimcore_area_navigation-bar-top') ? document.getElementsByClassName('pimcore_area_navigation-bar-top')[0] : null;

        let arr = [];

        if (bar1) {
            arr.push(bar1);
        }

        if (bar2) {
            arr.push(bar2);
        }

        return arr;
    }

    /**
     * Get hamburger html element or null if hamburger is not present
     *
     * @return {Element|null}
     */
    function getHamburger() {
        return document.getElementsByClassName('hamburger') ? document.getElementsByClassName('hamburger')[0] : null;
    }

    let accessibilityPopup = document.getElementById('accessibilityBox');
    let toggleAccessibilityPopup = document.getElementById('toggleAccessibilityPopup');
    let closePopup = document.getElementById('closeAccessibilityPopup');
    let contrastSwitcher = document.getElementById('contrastSwitcher');
    let emphasizedLinks = document.getElementById('emphasizedLinks');
    let saveAccessibilitySettings = document.getElementById('saveAccessibilitySettings');
    let removeAccessibilitySettings = document.getElementById('removeAccessibilitySettings');
    let contrastSwitcherChecked = contrastSwitcher.querySelector('input').checked;
    let emphasizedLinksChecked = emphasizedLinks.querySelector('input').checked;
    let fontSizeEmphasized = 1;
    let fontSize = document.getElementById('fontSize');
    let fontMinus = document.getElementById('fontMinus');
    let fontPlus = document.getElementById('fontPlus');

    checkCookiesAndSetup();

    function checkCookiesAndSetup() {
        let fontSizeCookie = checkCookie('fontSizeCookie');
        let intensifiedContrastCookie = checkCookie('intensifiedContrastCookie');
        let emphasizedLinksCookie = checkCookie('emphasizedLinksCookie');

        if (fontSizeCookie) {
            fontSize.innerText = fontSizeCookie;
            fontSizeEmphasized = parseInt(fontSizeCookie);

            if (parseInt(fontSizeCookie) === 1) {
                fontMinus.querySelector('svg').classList.add('disabled');
                fontMinus.classList.add('disabled');
            } else {
                fontMinus.querySelector('svg').classList.remove('disabled');
                fontMinus.classList.remove('disabled');
            }

            if (parseInt(fontSizeCookie) === 3) {
                fontPlus.querySelector('svg').classList.add('disabled');
                fontPlus.classList.add('disabled');
            } else {
                fontPlus.querySelector('svg').classList.remove('disabled');
                fontPlus.classList.remove('disabled');
            }
        } else {
            fontMinus.classList.add('disabled');
            fontMinus.querySelector('svg').classList.add('disabled');
        }
        

        if (emphasizedLinksCookie) {
            emphasizedLinks.querySelector('input').checked = true;
            emphasizedLinksChecked = true;
        } else {
            emphasizedLinks.querySelector('input').checked = false;
            emphasizedLinksChecked = false;
        }

        if (intensifiedContrastCookie) {
            contrastSwitcher.querySelector('input').checked = true;
            contrastSwitcherChecked = true;
        } else {
            contrastSwitcher.querySelector('input').checked = false;
            contrastSwitcherChecked = false;
        }

    }

    toggleAccessibilityPopup.addEventListener('click', () => {
        toggleAccessibility();
    });

    closePopup.addEventListener('click', () => {
        accessibilityPopup.classList.remove('shown');
    });

    contrastSwitcher.addEventListener('change', () => {
        contrastSwitcherChecked = contrastSwitcher.querySelector('input').checked;
    });

    emphasizedLinks.addEventListener('change', () => {
        emphasizedLinksChecked = emphasizedLinks.querySelector('input').checked;
    });

    fontPlus.addEventListener('click', () => {
        increaseFontSizeNumber();
    });

    fontMinus.addEventListener('click', () => {
        decreaseFontSizeNumber();
    });

    function increaseFontSizeNumber() {
        fontSizeEmphasized += 1;
        fontSize.innerText = fontSizeEmphasized;

        fontMinus.querySelector('svg').classList.remove('disabled');
        fontMinus.classList.remove('disabled');

        if (fontSizeEmphasized === 3) {
            fontPlus.querySelector('svg').classList.add('disabled');
            fontPlus.classList.add('disabled');
        }
    }

    function decreaseFontSizeNumber() {
        if (fontSizeEmphasized > 1 && fontSizeEmphasized <= 3) {
            fontSizeEmphasized -= 1;
            fontSize.innerText = fontSizeEmphasized;
            fontPlus.querySelector('svg').classList.remove('disabled');
            fontPlus.classList.remove('disabled');
        }

        if (fontSizeEmphasized === 1) {
            fontMinus.querySelector('svg').classList.add('disabled');
            fontMinus.classList.add('disabled');
        }
    }

    saveAccessibilitySettings.addEventListener('click', () => {
        if (contrastSwitcherChecked) {
            setCookie('intensifiedContrastCookie', '-contrast', 365);
        } else {
            setCookie('intensifiedContrastCookie', '', 365);
        }

        if (emphasizedLinksChecked) {
            setCookie('emphasizedLinksCookie', 'true', 365);
        } else {
            setCookie('emphasizedLinksCookie', '', 365);
        }

        setCookie('fontSizeCookie', fontSizeEmphasized, 365);
        location.reload();

    });

    removeAccessibilitySettings.addEventListener('click', () => {
        setCookie('fontSizeCookie', '1', 365);
        setCookie('emphasizedLinksCookie', '', 365);
        setCookie('intensifiedContrastCookie', '', 365);
        location.reload();
    });

    function toggleAccessibility() {
        accessibilityPopup.classList.toggle('shown');
    }

    function setCookie(cname, cvalue, exdays) {
        let d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = "expires="+d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
        let name = cname + "=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    function checkCookie(name) {
        let cookie = getCookie(name);
        if (cookie !== "") {
            return cookie;
        } else {
            return false;
        }
    }
}
