let navigationBar = require('./bricks/navigation-bar');
let navigationBarScroll = require('./bricks/navigation-bar-scroll');
let datePicker = require('./bricks/reservation-datepicker');
let validation = require('./includes/validation');
let textAreaCounter = require('./bricks/textarea-counter');
let photoSwiper = require('./includes/photoswipe');
let formExpander = require('./bricks/contact-form-expander');
let cart = require('./pages/cart');
let checkout = require('./pages/checkout');
let cookies = require('./includes/cookies');
import * as PhotoSwipe from 'photoswipe'
import * as PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default'

window.PhotoSwipe = PhotoSwipe;
window.PhotoSwipeUI_Default = PhotoSwipeUI_Default;

navigationBar.setupMobileMenu();
navigationBarScroll.setupNavigationBarScroll();
textAreaCounter.activateCounter();
formExpander.activateExpander();
cookies.checkCookies();
document.querySelector('#cookie-button').addEventListener('click', () => {
    cookies.setCookies("terms", "true", 365);
});

import Swiper from 'swiper';
import Litepicker from 'litepicker';
import 'litepicker-module-navkeyboard';

datePicker.activatePicker(document.getElementById('date'));

if (document.querySelector('.photos')) {
    let thumbnails = document.getElementsByClassName('galleryThumbnail');
    for (let i = 0; i < thumbnails.length; i++) {
        thumbnails[i].addEventListener('click', () => {
            photoSwiper.gallery();
        });
    }

    document.querySelector('.photos').addEventListener('keyup', (event) => {    // Accessibility with keyboard
        if (event.key === 'Enter') {
            photoSwiper.gallery();
        }
    })
}

window.onSubmit = function() {
    validation.emailValidation(document.querySelector('#email'));
}

if (document.querySelector('.fill-your-time')) {
    const swiper = new Swiper('.swiper-container', {
        slidesPerView: 'auto',
        loop: true,
        freeMode: true,
        breakpoints: {
            320: {
                spaceBetween: 10
            },
            480: {
                spaceBetween: 15
            },
            720: {
                spaceBetween: 20
            },
            1400: {
                spaceBetween: 30
            }
        }
    })

    document.body.addEventListener('keyup', (event) => {
   if (event.target.classList.contains('accordion-icon')) {
       if (event.key === 'Enter') {         // For accessibility
           let acc = document.querySelectorAll('.accordion-single');   // All accordion panels
           checkPanel(event.target, acc);
       }
   }
});document.body.addEventListener('click', (event) => {
        if (event.target.classList.contains('accordion-icon')) {
            let acc = document.querySelectorAll('.accordion-single');   // All accordion panels
            checkPanel(event.target, acc);
        }

        if (event.target.classList.contains('prev-button')) {
            swiper.slidePrev();
        }

        if (event.target.classList.contains('next-button')) {
            swiper.slideNext();
        }
    });
}

if (document.querySelector('#newsletter-form')) {
    document.querySelector('#email').addEventListener('focus', () => {
        document.querySelector('#email').classList.toggle('form-error', false);
        document.querySelector('.newsletter-failed').style.display = "none";
        document.querySelector('.newsletter-success').style.display = "none";
    });
}

if (document.querySelector('#number_of_guests')) {
    document.querySelector('#number_of_guests').addEventListener('change', (event) => {
        document.querySelector('#guests-form').submit();
    });
}

if (document.querySelector('.cart-content')) {
    let links = Array.from(document.getElementsByClassName('link'));
    let modal = document.querySelector('.popup-room');

    cart.checkButtons();
    //document.querySelector('.date-picker')

    /*document.querySelector('.close-icon').addEventListener('click', () => {
        cart.closePopup();
    });*/
    window.onclick = (event) => {
        if (event.target == modal) {
            cart.closePopup();
        }
    };
    document.addEventListener('keyup', (event) => {
       if (event.key === "Escape") {
           cart.closePopup();
       }
    });
}

if (document.querySelector('#checkout-form')) {
    checkout.checkBusiness(document.querySelector('#business-toggle'));
    checkout.checkForm(document.querySelector('#checkout-form'));

    document.querySelector('#checkout-form').addEventListener('change', () => {
       checkout.checkForm(document.querySelector('#checkout-form'));
    });
    document.querySelector('#checkout-form').addEventListener('keyup', () => {
        checkout.checkForm(document.querySelector('#checkout-form'));
    });
}

if (document.querySelector('#use-your-time')) {
    document.querySelector('#type').addEventListener('change', (event) => {
        document.querySelector('#use-your-time').submit();
    });

    if ( document.querySelector('#filter')) {
        document.querySelector('#filter').addEventListener('change', (event) => {
            document.querySelector('#use-your-time').submit();
        });
    }
}

function checkPanel(panel, acc) {
    if (panel.parentElement.classList.contains('active')) {     // Pressing on an already open panel
        panel.parentElement.classList.toggle('active');
        panel.parentElement.querySelector('.single-description').classList.toggle('active');
        panel.dataset.icon = changeDataIcon(panel.dataset.icon);
        toggleIcon(panel.children);
    } else {    // Pressing on a closed panel
        removeActives(acc);
        panel.parentElement.classList.toggle('active', true);
        panel.parentElement.querySelector('.single-description').classList.toggle('active', true);
        panel.dataset.icon = changeDataIcon(panel.dataset.icon);
        toggleIcon(panel.children);
    }
}

function removeActives(acc) {
    for (let i = 0; i < acc.length; i++) {
        if (acc[i].classList.contains('active')) {
            acc[i].querySelector('.accordion-icon').dataset.icon = "closed";
            acc[i].querySelector('.single-description').classList.toggle('active', false);
            toggleIcon(acc[i].querySelector('.accordion-icon').children);
            acc[i].classList.toggle('active', false);
        }
    }
}

function changeDataIcon(icon) {
    if (icon == "open") {
        return "closed"
    } else {
        return "open"
    }
}

function toggleIcon(icons) {
    for (let i = 0; i < icons.length; i++) {
        icons[i].classList.toggle('hidden');
    }
}
