export function activateCounter(datePicker) {
    const textAreaField = document.getElementsByClassName("counter-textarea-field");

    for (let i = 0; i < textAreaField.length; i++) {
        textAreaField[i].addEventListener("keypress", onType);
    }

    function onType(e) {
        const counterElement = document.getElementById(e.target.attributes["name"].value + '-counter');
        const maxChars = e.target.attributes["maxlength"].value;

        counterElement.innerHTML = e.target.value.length + '/' + maxChars;
    }
}
