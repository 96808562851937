export function activateExpander() {
    const expanders = document.getElementsByClassName("expand-button");
    const contractors = document.getElementsByClassName("contract-button");
    const formHolder = document.getElementsByClassName("form-holder")[0];
    const rightPart = document.getElementsByClassName("right-part-expander")[0];
    let expander;
    let contractor;

    if (expanders && expanders.length > 0) {
        expander = expanders[0];
    } else {
        return;
    }

    if (contractors && contractors.length > 0) {
        contractor = contractors[0];
    } else {
        return;
    }

    if (window.shouldToggleOnOpen) {
        toggleForm();
    }

    expander.addEventListener("click", toggleForm);
    expander.addEventListener("keyup", (event) => {
        if (event.key === 'Enter') {
            toggleForm();
        }
    });
    contractor.addEventListener("click", toggleForm);
    contractor.addEventListener("keyup", (event) => {
        if (event.key === 'Enter') {
            toggleForm();
        }
    });

    function toggleForm(e) {

        if (formHolder.classList.contains('expanded')) {
            formHolder.classList.remove('expanded');
            rightPart.classList.remove('expanded');
        } else {
            formHolder.classList.add('expanded');
            rightPart.classList.add('expanded');
        }
    }

}
