let validation = require('../includes/validation');

export function checkBusiness(toggle) {

    if (toggle.checked) {
        document.querySelector('.form-business').style.display = "block";
    }

    toggle.addEventListener('click', (event) => {
        if (event.target.checked) {
            document.querySelector('.form-business').style.display = "block";
        } else {
            document.querySelector('.form-business').style.display = "none";
        }
    })
}

export function checkForm(form) {
    /*let elements = Array.from(form.elements);
    let email = document.querySelector('#email-checkout');

    if (document.querySelector('#business-toggle').checked) {
        elements = elements.slice(1, elements.length - 3);
    } else {
        elements = elements.slice(4, elements.length - 3)
    }

    if (checkEmpty(elements) && checkTerms(document.querySelector('#terms')) && !validation.emailValidation(email.value)) {
        document.querySelector('#checkout-button').disabled = false;
        document.querySelector('#checkout-button').classList.toggle('primary-disabled', false);
    } else {
        document.querySelector('#checkout-button').disabled = true;
        document.querySelector('#checkout-button').classList.toggle('primary-disabled', true);
    }*/
}

function checkTerms(terms) {
    if (terms.checked) {
        return true;
    } else {
        return false;
    }
}

function checkEmpty(elements) {
    for (let i = 0; i < elements.length; i++) {
        if (elements[i].value == "") {
            return false;
        }
    }
    return true;
}
