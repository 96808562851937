export function subscribe(token) {
    const axios = require('axios');
    var formData = new FormData(document.querySelector('#newsletter-form'));

    axios({
        method: 'post',
        url: mailchimpRoute,
        data: formData,
        headers: {'Content-Type': 'multipart/form-data' }
    })
        .then(function (response) {
            if (response.data.status == 'success') {
                //document.querySelector('.email-form').style.display = "none";
                document.querySelector('.newsletter-success').style.display = "block";
            } else if (response.data.message && response.data.message.includes("Member Exists")) {
                //document.querySelector('.email-form').style.display = "none";
                document.querySelector('.newsletter-exists').style.display = "block";
            }
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
}