import * as PhotoSwipe from 'photoswipe'
import * as PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default'


export function gallery() {
    var pswpElement = document.querySelectorAll('.pswp')[0];
    var options = {
        loop: true,
        bgOpacity: 0.4,
        fullscreenEl: false,
        shareEl:  false,
        tapToClose:  true,
        tapToToggleControls:  false
    }

    var gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, imageGallery, options);
    gallery.init();
}
