export function setupNavigationBarScroll() {
    /**
     * Old window scroll y position
     * @type Number
     */
    let oldY;

    /**
     * Direction on which to last scroll occurred
     * It could be either up or down
     *
     * @type string
     */
    let direction;

    /**
     * Navigation bar elements
     * @type {undefined|[]}
     */
    let navBars = getNavigationBars();

    /**
     * Home logo element
     *
     * @type {Element|null}
     */
    let logos = getHomeLogo();

    setupEvents();

    /**
     * Setup scrolling events
     */
    function setupEvents() {

        if (navBars) {

            window.addEventListener('scroll', () => {
                onPageScroll();
            })
        }
    }

    /**
     * On page scroll event
     */
    function onPageScroll() {

        if (oldY > window.scrollY) {

            if (direction !== 'up') {
                showNavBar();
                direction = 'up'
                showLogo();
            }

            maybeHideLogo();
        }

        if (oldY < window.scrollY) {

            if (direction !== 'down') {
                hideNavBar();
                direction = 'down';
            }
        }

        oldY = window.scrollY;
    }

    /**
     * Show navigation bar
     */
    function showNavBar() {

        navBars.forEach((item) => {
            item.style.top = '0px';
        });
    }

    /**
     * Show logo if scrolled too much from top
     */
    function showLogo() {

        if (window.pageYOffset > window.innerHeight) {

            if (logos) {

                for (let logo of logos) {
                    logo.style.width = 'auto';
                    logo.style.marginLeft = '18px';
                }
            }
        }
    }

    /**
     * Hide logo if hero image is visible
     */
    function maybeHideLogo() {

        if (location.pathname !== '/') {
            return;
        }

        if (window.pageYOffset < window.innerHeight) {

            if (logos) {

                for (let logo of logos) {
                    logo.style.width = '0';
                    logo.style.marginLeft = '0';
                }
            }
        }
    }

    /**
     * Hide navigation bar
     */
    function hideNavBar() {

        navBars.forEach((item) => {

            if (item.style.position === 'fixed') {
                item.style.top = '0px';
                return;
            }

            item.style.top = '-60px';
        });
    }

    /**
     * get navigation bar elements
     * We have two types of navigation bar elements
     *
     * One is for home and the other is used on all other pages
     *
     * @return {[]}
     */
    function getNavigationBars() {
        let bar1 = document.getElementsByClassName('pimcore_area_navigation-bar') ? document.getElementsByClassName('pimcore_area_navigation-bar')[0] : null;
        let bar2 = document.getElementsByClassName('pimcore_area_navigation-bar-top') ? document.getElementsByClassName('pimcore_area_navigation-bar-top')[0] : null;

        if (!bar1 && !bar2) {
            return;
        }

        let arr = [];

        if (bar1) {
            arr.push(bar1);
        }

        if (bar2) {
            arr.push(bar2);
        }

        return arr;
    }

    /**
     * Get home logo
     *
     * @return {HTMLCollectionOf<Element>|null}
     */
    function getHomeLogo() {
        return document.getElementsByClassName('home-logo') ? document.getElementsByClassName('home-logo') : null;
    }
}
