export function emailValidation(input) {
    let subscribeNewsletter = require('../bricks/newsletter');

    function testValue(email) {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
            return false;
        } else {
            return true;
        }
    }

    if (testValue(input.value)) {
        document.querySelector('#email').classList.toggle('form-error', true);
        document.querySelector('.newsletter-failed').style.display = "block";
        document.querySelector('#newsletter-form').preventDefault();
    } else if (input.value == "") {
        document.querySelector('#email').classList.toggle('form-error', true);
        document.querySelector('.newsletter-failed').style.display = "block";
        document.querySelector('#newsletter-form').preventDefault();
    } else {
        subscribeNewsletter.subscribe();
        document.querySelector('#email').target.classList.toggle('form-error', false);
        document.querySelector('.newsletter-failed').style.display = "none";
    }
}

