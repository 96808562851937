import 'litepicker-module-navkeyboard';

export function activatePicker(datePicker) {

    if (!datePicker) {
        return;
    }

    let tooltip = {};
    if (window.language == 'hr') {
        tooltip = {
            one: 'noć',
            few: 'noći',
            other: 'noći'
        }
    } else {
        tooltip = {
            one: 'night',
            few: 'nights',
            other: 'nights'
        }
    }

    const picker = new Litepicker({
        element: datePicker,
        format: window.dateFormat,
        singleMode: false,
        mobileFriendly: true,
        minDate: getToday(),
        maxDate: getMaxDate(),
        minDays: 1,
        numberOfMonths: 2,
        numberOfColumns: 1,
        hotelMode: true,
        lang: window.language,
        tooltipText: tooltip,
        moduleNavKeyboard: true,
        onSelect: (date1, date2) => {
            if (document.querySelector('.cart-content')) {
                if (date1 != "") {
                    document.querySelector('#datepicker-button').disabled = false;
                }
            }
        }
    });

    function getToday() {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0');
        let yyyy = today.getFullYear();

        today = mm + '/' + dd + '/' + yyyy;

        return today;
    }

    function getMaxDate() {
        let today = new Date();
        return today.setMonth(today.getMonth() + 24);
    }
}
