export function checkButtons() {
    if (document.querySelector('.date-picker').value == "") {
        document.querySelector('#datepicker-button').disabled = true;
        //document.querySelector('.continue').disabled = true; //should toggle on when a room is selected not when date is selected
    }
}

export function openPopup() {
    document.querySelector('.popup-room').style.display = "block";
}

export function closePopup() {
    document.querySelector('.popup-room').style.display = "none";
}
